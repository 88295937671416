import React, { useState } from 'react';

// Tarih formatı kontrolü
const isValidDate = (dateString) => {
  const regex = /^(0[1-9]|[12][0-9]|3[01])\.(0[1-9]|1[012])\.(19|20)\d\d$/;
  if (!regex.test(dateString)) return false;
  
  const parts = dateString.split('.');
  const day = parseInt(parts[0], 10);
  const month = parseInt(parts[1], 10);
  const year = parseInt(parts[2], 10);
  
  const date = new Date(year, month - 1, day);
  return date.getDate() === day && date.getMonth() === month - 1 && date.getFullYear() === year;
};

// Telefon numarası formatı kontrolü
const isValidPhone = (phone) => {
  const regex = /^5[0-9]{9}$/;
  return regex.test(phone);
};

const DaskForm = () => {
  const [formData, setFormData] = useState({
    tcKimlikNo: '',
    dogumTarihi: '',
    oncekiPoliceNo: '',
    telefonNo: ''
  });
  
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [teklifSonuc, setTeklifSonuc] = useState(null);
  const [validation, setValidation] = useState({
    tcKimlikNo: true,
    dogumTarihi: true,
    telefonNo: true
  });

  const validateForm = () => {
    const newValidation = {
      tcKimlikNo: formData.tcKimlikNo.length === 11 && /^\d+$/.test(formData.tcKimlikNo),
      dogumTarihi: isValidDate(formData.dogumTarihi),
      telefonNo: isValidPhone(formData.telefonNo)
    };
    
    setValidation(newValidation);
    return Object.values(newValidation).every(v => v);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
    
    setValidation(prev => ({
      ...prev,
      [name]: true
    }));
  };

  const formatPhoneNumber = (value) => {
    const numbers = value.replace(/\D/g, '');
    return numbers;
  };

  const handlePhoneChange = (e) => {
    const formattedNumber = formatPhoneNumber(e.target.value);
    setFormData(prev => ({
      ...prev,
      telefonNo: formattedNumber
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (!validateForm()) {
      setError('Lütfen tüm alanları doğru formatta doldurunuz.');
      return;
    }

    setLoading(true);
    setError('');

    try {
      const requestBody = {
        cevaplar: [
          {
            Soru: "UrunGrubu",
            Cevap: "dask",
            Zorunlu: false
          },
          {
            Soru: "UrunKodu",
            Cevap: "199",
            Zorunlu: false
          },
          {
            Soru: "MusteriTcKimlikNo",
            Cevap: formData.tcKimlikNo,
            Zorunlu: false
          },
          {
            Soru: "MusteriDogumTarihi",
            Cevap: formData.dogumTarihi,
            Zorunlu: false
          },
          {
            Soru: "DaskOncekiPoliceNo",
            Cevap: formData.oncekiPoliceNo || "",
            Zorunlu: false
          },
          {
            Soru: "MusteriCepTelefonNo",
            Cevap: formData.telefonNo,
            Zorunlu: false
          }
        ]
      };

      const response = await fetch('https://www.sigortasatinal.com/dask-api.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody)
      });
      
      // Debug için
      console.log('Request:', requestBody);
      const responseText = await response.text();
      console.log('Raw response:', responseText);

      let result;
      try {
        result = JSON.parse(responseText);
      } catch (parseError) {
        throw new Error(`API yanıtı JSON formatında değil: ${responseText.substring(0, 100)}...`);
      }

      if (!result.success) {
        throw new Error(result.error || 'Teklif alınırken bir hata oluştu');
      }

      setTeklifSonuc(result.data);
    } catch (err) {
      console.error('Tam hata:', err);
      setError(err.message || 'Teklif alınırken bir hata oluştu. Lütfen tekrar deneyiniz.');
    } finally {
      setLoading(false);
    }
  };
  return (
    <div className="max-w-2xl mx-auto p-4">
      <div className="bg-white shadow-lg rounded-lg overflow-hidden">
        <div className="p-6 bg-blue-600">
          <h2 className="text-2xl font-bold text-white">DASK Teklifi Al</h2>
          <p className="text-blue-100 mt-2">
            Zorunlu Deprem Sigortası için hemen teklif alın.
          </p>
        </div>

        <div className="p-6">
          <form onSubmit={handleSubmit} className="space-y-6">
            <div>
              <label htmlFor="tcKimlikNo" className="block text-sm font-medium text-gray-700">
                TC Kimlik Numarası
              </label>
              <input
                type="text"
                id="tcKimlikNo"
                name="tcKimlikNo"
                value={formData.tcKimlikNo}
                onChange={handleInputChange}
                placeholder="TC Kimlik Numarası"
                className={`mt-1 block w-full rounded-md shadow-sm py-2 px-3 border ${
                  !validation.tcKimlikNo ? 'border-red-500' : 'border-gray-300'
                } focus:outline-none focus:ring-2 focus:ring-blue-500`}
              />
              {!validation.tcKimlikNo && (
                <p className="mt-1 text-sm text-red-500">Geçerli bir TC Kimlik Numarası giriniz</p>
              )}
            </div>

            <div>
              <label htmlFor="dogumTarihi" className="block text-sm font-medium text-gray-700">
                Doğum Tarihi
              </label>
              <input
                type="text"
                id="dogumTarihi"
                name="dogumTarihi"
                value={formData.dogumTarihi}
                onChange={handleInputChange}
                placeholder="GG.AA.YYYY"
                className={`mt-1 block w-full rounded-md shadow-sm py-2 px-3 border ${
                  !validation.dogumTarihi ? 'border-red-500' : 'border-gray-300'
                } focus:outline-none focus:ring-2 focus:ring-blue-500`}
              />
              {!validation.dogumTarihi && (
                <p className="mt-1 text-sm text-red-500">Geçerli bir tarih giriniz (GG.AA.YYYY)</p>
              )}
            </div>

            <div>
              <label htmlFor="telefonNo" className="block text-sm font-medium text-gray-700">
                Telefon Numarası
              </label>
              <input
                type="text"
                id="telefonNo"
                name="telefonNo"
                value={formData.telefonNo}
                onChange={handlePhoneChange}
                placeholder="5XXXXXXXXX"
                maxLength={10}
                className={`mt-1 block w-full rounded-md shadow-sm py-2 px-3 border ${
                  !validation.telefonNo ? 'border-red-500' : 'border-gray-300'
                } focus:outline-none focus:ring-2 focus:ring-blue-500`}
              />
              {!validation.telefonNo && (
                <p className="mt-1 text-sm text-red-500">Geçerli bir telefon numarası giriniz</p>
              )}
            </div>

            <div>
              <label htmlFor="oncekiPoliceNo" className="block text-sm font-medium text-gray-700">
                Önceki Poliçe Numarası (Varsa)
              </label>
              <input
                type="text"
                id="oncekiPoliceNo"
                name="oncekiPoliceNo"
                value={formData.oncekiPoliceNo}
                onChange={handleInputChange}
                placeholder="Önceki DASK poliçe numarası"
                className="mt-1 block w-full rounded-md shadow-sm py-2 px-3 border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
            </div>

            {error && (
              <div className="p-4 bg-red-50 border-l-4 border-red-500 text-red-700">
                <p>{error}</p>
              </div>
            )}

            <button
              type="submit"
              disabled={loading}
              className={`w-full py-3 px-4 text-white font-medium rounded-md ${
                loading
                  ? 'bg-blue-400 cursor-not-allowed'
                  : 'bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500'
              }`}
            >
              {loading ? (
                <div className="flex items-center justify-center">
                  <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                  </svg>
                  Teklif Alınıyor...
                </div>
              ) : (
                'Teklif Al'
              )}
            </button>
          </form>

          {teklifSonuc && (
            <div className="mt-8 space-y-6">
              <h3 className="text-xl font-bold text-gray-900">Teklif Sonucu</h3>
              <div className="space-y-4">
                {teklifSonuc.map((sonuc, index) => (
                  <div key={index} className="bg-gray-50 rounded-lg p-4 space-y-4">
                    <div className="flex justify-between items-center border-b border-gray-200 pb-2">
                      <span className="font-medium text-gray-700">{sonuc.taksitTipi}</span>
                      <span className="text-xl font-bold text-blue-600">{sonuc.brutPrim} TL</span>
                    </div>
                    
                    <div>
                      <p className="text-sm text-gray-600 mb-2">Ödeme Planı</p>
                      <div className="space-y-2">
                        {sonuc.odemePlani.map((odeme, idx) => (
                          <div key={idx} className="flex justify-between items-center p-2 bg-white rounded shadow-sm">
                            <span className="text-sm text-gray-600">{odeme.vadeTarihi}</span>
                            <span className="font-medium text-gray-900">{odeme.tutar} TL</span>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              
              <div className="mt-4 p-4 bg-blue-50 rounded-lg">
                <p className="text-sm text-blue-700">
                  * Teklif bilgileri gösterilen tarihler için geçerlidir. 
                  Poliçe düzenleme aşamasında primler değişiklik gösterebilir.
                </p>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default DaskForm;