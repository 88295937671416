import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Star, Users, Shield, ChevronLeft, ChevronRight, Menu, X } from 'lucide-react';

const logos = [
  { src: "/logos/aksigortalogo.jpg", alt: "Ak Sigorta" },
  { src: "/logos/allianzlogo.jpg", alt: "Allianz" },
  { src: "/logos/anadolulogo.jpg", alt: "Anadolu Sigorta" },
  { src: "/logos/anasigorta.jpg", alt: "Ana Sigorta" },
  { src: "/logos/axalogo.jpg", alt: "AXA Sigorta" },
  { src: "/logos/axahayatlogo.png", alt: "AXA Hayat Emeklilik" },
  { src: "/logos/dogasigorta.jpg", alt: "Doğa Sigorta" },
  { src: "/logos/hdisigorta.jpg", alt: "HDİ Sigorta" },
  { src: "/logos/hepiyisigorta.jpg", alt: "Hepiyi Sigorta" },
  { src: "/logos/magdeburgerlogo.jpg", alt: "Magdeburger Sigorta" },
  { src: "/logos/neovasigorta.jpg", alt: "Neova Sigorta" },
  { src: "/logos/somposigorta.jpg", alt: "Sompo Sigorta" },
  { src: "/logos/turkiyesigorta.jpg", alt: "Türkiye Sigorta" },
  { src: "/logos/turkiyehayat.png", alt: "Türkiye Hayat Emeklilik" },
  { src: "/logos/turkiyekatilim.jpg", alt: "Türkiye Katılım Sigorta" },
  { src: "/logos/katılım_sigorta_hayat.jpg", alt: "Türkiye Katılım Hayat" },



];

const reviews = [
  { name: 'Ahmet Y.', title: 'Müşteri', comment: 'Tamamlayıcı sağlık sigortası seçenekleriniz gerçekten hayat kurtarıcı oldu. Hem bütçemize uygun hem de ihtiyaçlarımızı tam karşılayan bir poliçemiz var artık.' },
  { name: 'Ayşe K.', title: 'İş Sahibi', comment: 'İhtiyaçlarımı tam anlamıyla karşılayan profesyonel bir ekiple çalışmak çok keyifliydi. Sizlerle işbirliği yapmak gerçekten güven verici.' },
  { name: 'Mehmet S.', title: 'Öğretmen', comment: 'Kasko sigortası ile araç güvenliğimizi garanti altına aldık, sürecin bu kadar hızlı ve sorunsuz ilerlemesi bizi çok memnun etti. Hem uygun fiyatlar hem de profesyonel destek için teşekkürler!' },
  { name: 'Fatma Ö.', title: 'Doktor', comment: 'Her zaman müşteri odaklı yaklaşımları var.' },
];

const CustomerReviews = ({ reviews }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const nextSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % reviews.length);
  };

  const prevSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + reviews.length) % reviews.length);
  };

  useEffect(() => {
    const interval = setInterval(nextSlide, 5000); // Auto-slide every 5 seconds
    return () => clearInterval(interval);
  }, []);

  return (
    <section className="py-16 px-4 bg-gray-100">
      <div className="container mx-auto">
        <h2 className="text-3xl font-bold text-center mb-12">Müşteri Yorumları</h2>
        <div className="relative max-w-4xl mx-auto">
          <div className="overflow-hidden">
            <div 
              className="flex transition-transform duration-300 ease-in-out" 
              style={{ transform: `translateX(-${currentIndex * 100}%)` }}
            >
              {reviews.map((review, index) => (
                <div key={index} className="w-full flex-shrink-0 px-4">
                  <div className="bg-white p-6 rounded-lg shadow-lg">
                    <p className="text-gray-600 mb-4 text-lg italic">"{review.comment}"</p>
                    <div className="flex items-center">
                      <div className="w-12 h-12 rounded-full bg-gray-300 mr-4"></div>
                      <div>
                        <p className="font-semibold text-lg">{review.name}</p>
                        <p className="text-sm text-gray-500">{review.title}</p>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <button 
            onClick={prevSlide}
            className="absolute left-0 top-1/2 transform -translate-y-1/2 bg-white rounded-full p-2 shadow-md hover:bg-gray-100 transition-colors duration-200"
            aria-label="Önceki yorum"
          >
            <ChevronLeft className="w-6 h-6 text-red-600" />
          </button>
          <button 
            onClick={nextSlide}
            className="absolute right-0 top-1/2 transform -translate-y-1/2 bg-white rounded-full p-2 shadow-md hover:bg-gray-100 transition-colors duration-200"
            aria-label="Sonraki yorum"
          >
            <ChevronRight className="w-6 h-6 text-red-600" />
          </button>
        </div>
        <div className="flex justify-center mt-6">
          {reviews.map((_, index) => (
            <button
              key={index}
              onClick={() => setCurrentIndex(index)}
              className={`w-3 h-3 rounded-full mx-1 ${
                index === currentIndex ? 'bg-red-600' : 'bg-gray-300'
              }`}
              aria-label={`Yorum ${index + 1}`}
            />
          ))}
        </div>
      </div>
    </section>
  );
};

export default function SigortaAcentesiAnasayfa() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  return (
    <div className="bg-red text-gray-800">
      {/* Header */}
      <header className="bg-white-600 text-black p-4">
        <nav className="container mx-auto flex justify-between items-center">
          <div className="flex items-center">
            <a href="https://www.sigortasatinal.com" aria-label="Anasayfa">
              <img
                src={process.env.PUBLIC_URL + '/logos/centalya.webp'}
                alt="Şirket Logosu"
                className="h-14 mr-6"
              />
            </a>
          </div>

          {/* Desktop Menu */}
          <div className="hidden md:flex space-x-4">
            <Link to="/urunler" className="hover:text-red-200">Ürünler</Link>
            <Link to="/hakkimizda" className="hover:text-red-200">Hakkımızda</Link>
            <Link to="/iletisim" className="hover:text-red-200">İletişim</Link>
          </div>
          
          {/* Mobile Menu Button */}
          <button 
            className="md:hidden"
            onClick={() => setIsMenuOpen(!isMenuOpen)}
          >
            {isMenuOpen ? <X size={24} /> : <Menu size={24} />}
          </button>
        </nav>

        {/* Mobile Menu */}
        {isMenuOpen && (
          <div className="md:hidden mt-2">
            <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
              <Link to="/urunler" className="block px-3 py-2 rounded-md text-base font-medium hover:bg-white-700">Ürünler</Link>
              <Link to="/hakkimizda" className="block px-3 py-2 rounded-md text-base font-medium hover:bg-white-700">Hakkımızda</Link>
              <Link to="/iletisim" className="block px-3 py-2 rounded-md text-base font-medium hover:bg-white-700">İletişim</Link>
            </div>
          </div>
        )}
      </header>

      {/* Hero Section */}
      <section className="bg-red-500 text-white py-20 px-4">
        <div className="container mx-auto text-center">
          <h1 className="text-5xl font-bold mb-6">Geleceğinizi Güvence Altına Alın</h1>
          <p className="text-xl mb-8">Size özel sigorta çözümleriyle yanınızdayız</p>
          <div className="flex flex-wrap justify-center gap-4">
            <Link to="/trafik-sigortasi-fiyat" className="bg-white text-red-600 px-6 py-3 rounded-full font-semibold hover:bg-gray-100 transition duration-300">
              Trafik Sigortası Fiyat Al
            </Link>
            <Link to="/kasko-fiyat" className="bg-white text-red-600 px-6 py-3 rounded-full font-semibold hover:bg-gray-100 transition duration-300">
              Kasko Fiyat Al
            </Link>
            <Link to="/tamamlayici-saglik-fiyat" className="bg-white text-red-600 px-6 py-3 rounded-full font-semibold hover:bg-gray-100 transition duration-300">
              Tamamlayıcı Sağlık Sigortası Fiyat Al
            </Link>
          </div>
        </div>
      </section>

      {/* Neden Biz? Section */}
      <section className="py-16 px-4 bg-gray-100">
        <div className="container mx-auto">
          <h2 className="text-3xl font-bold text-center mb-12">Neden Biz?</h2>
          <div className="grid md:grid-cols-3 gap-8">
            {[
              { icon: Star, title: 'Uzmanlık', desc: '15 Yıllık Tecrübe Dolu Deneyim' },
              { icon: Users, title: 'Müşteri Odaklı', desc: 'Müşteri Odaklı Hizmet' },
              { icon: Shield, title: 'Güvenilirlik', desc: 'Sektörde Önde Gelen Şirketler' }
            ].map((item, index) => (
              <div key={index} className="text-center">
                <item.icon className="w-16 h-16 mx-auto text-red-600 mb-4" />
                <h3 className="text-xl font-semibold mb-2">{item.title}</h3>
                <p>{item.desc}</p>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Tecrübe Section */}
      <section className="py-16 px-4">
        <div className="container mx-auto text-center">
          <h2 className="text-3xl font-bold mb-6">15 Yıllık Tecrübe</h2>
          <p className="text-xl mb-8">
            15 yıllık tecrübemizle, sektördeki tüm yenilikleri yakından takip ediyor ve müşterilerimize en güncel çözümleri sunuyoruz.
          </p>
        </div>
      </section>

      {/* Çalıştığımız Şirketler */}
      <section className="py-16 px-4 bg-white">
        <div className="container mx-auto">
          <h2 className="text-3xl font-bold text-center mb-12">Çalıştığımız Şirketler</h2>
          <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-6 gap-6">
            {logos.map((logo, index) => (
              <div key={index} className="flex items-center justify-center">
                <div className="bg-white p-2 rounded-lg shadow-md border border-gray-200 w-50 h-40 flex items-center justify-center">
                  <img 
                    src={process.env.PUBLIC_URL + logo.src} 
                    alt={logo.alt} 
                    className="max-h-50 max-w-full object-contain" 
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Müşteri Yorumları */}
      <CustomerReviews reviews={reviews} />

      {/* Footer */}
      <footer className="bg-red-600 text-white py-8 px-4">
        <div className="container mx-auto grid md:grid-cols-3 gap-8">
          <div>
            <h3 className="text-xl font-semibold mb-4">Centalya Sigorta Aracılık Hiz. Ltd. Şti.</h3>
            <p>Geleceğinizi güvence altına alıyoruz.</p>
          </div>
          <div>
            <h3 className="text-xl font-semibold mb-4">İletişim</h3>
            <p>Kanal Mah. Yasemin Cad. Nil Apt. no: 7/8 Kepez Antalya</p>
            <p>Telefon: (0242) 331 03 00</p>
            <p>E-posta: info@centalyasigorta.com.tr</p>
          </div>
          <div>
            <h3 className="text-xl font-semibold mb-4">Bizi Takip Edin</h3>
            <div className="flex space-x-4">
              <a href="#" className="hover:text-gray-200"></a>
              <a href="#" className="hover:text-gray-200"></a>
              <a href="#" className="hover:text-gray-200"></a>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
}